import { ConeParticleEmitter, ParticleHelper, ParticleSystem, Scene, Texture, Vector3 } from "@babylonjs/core";

export const MakeBubbles = (scene: Scene, position: Vector3, start = true) => {
    const particles = ParticleHelper.CreateDefault(position, 20, scene);
    particles.particleEmitterType = new ConeParticleEmitter(1, Math.PI / 2, 0.5);
    particles.name = `Bubbles`;

    particles.particleTexture = new Texture("assets/textures/bol.png", scene);

    particles.blendMode = ParticleSystem.BLENDMODE_ADD;
    
    particles.minEmitPower = 1;
    particles.maxEmitPower = 2;

    particles.minLifeTime = 0.7;
    particles.maxLifeTime = 1.2;

    particles.colorDead = particles.color1;
    particles.addSizeGradient(0, 0);
    particles.addSizeGradient(1, 0.2, 0.4);

    particles.updateSpeed = 0.007;

    particles.start();

    return particles;
};