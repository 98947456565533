import { Behavior, TransformNode } from "@babylonjs/core";
import { IWaypoint } from "../data/IWaypoint";
import MoveToPoint from "./MoveToPoint";
import BehaviorsUtils from "./utils";

class FollowWaypoints implements Behavior<TransformNode> {
    name = "FollowWaypoints"
    private _movement: MoveToPoint
    private _next: IWaypoint

    follow(waypoint: IWaypoint) {
        this._next = waypoint;
        this._movement.goTo(waypoint.position, this.onReachedWaypoint.bind(this));
    }

    private onReachedWaypoint() {
        if (this._next.next)
            this.follow(this._next.next);
    }

    init(): void {
    }

    attach(target: TransformNode): void {
        this._movement = BehaviorsUtils.getOrAddBehavior(target, MoveToPoint);
    }

    detach(): void {
    }
    
}

export default FollowWaypoints;