import { Vector3 } from "@babylonjs/core";
import { FishPrefab } from "./fish-prefab"
import { PrefabLoader } from "./prefab"

export class CavallucPrefab extends FishPrefab {
    init() {
        super.init();
        this.root.scaling = new Vector3(2.5, 2.5, 2.5);
    }
}

export class CavallucLoader extends PrefabLoader<CavallucPrefab> {
    path = "models/fish_01/"
    filename = "cavalluc.glb"
    prefabConstructor = CavallucPrefab
};