import { AnimationGroup, ShadowGenerator, TransformNode } from "@babylonjs/core";
import { Prefab, PrefabLoader } from "./prefab";

export class AmemoPrefab extends Prefab {
    initInstance(instance: TransformNode, retargetedGroups: AnimationGroup[]) {
        retargetedGroups[0].play(true);
        retargetedGroups[0].goToFrame(Math.floor(Math.random() * 30));

        const light = instance.getScene().lights[0];
        const shadowGenerator = light.getShadowGenerator() as ShadowGenerator;
        if (shadowGenerator)
            shadowGenerator.addShadowCaster(instance.getChildMeshes()[1]);
    }
}

export class AmemoPrefabLoader extends PrefabLoader<AmemoPrefab> {
    path = "models/"
    filename = "amemo.glb"
    prefabConstructor = AmemoPrefab
}