import FishSpawner from "./FishSpawner";

class FishTargetCountSetter {
    spawner: FishSpawner

    constructor(spawner: FishSpawner) {
        this.spawner = spawner;

        this.updateTargetCount();
        setInterval(this.updateTargetCount.bind(this), 1000 * 60);
    }

    updateTargetCount() {
        fetch("/get-ambassadors")
            .then(response => response.json())
            .then(json => {
                let count: number = json.ambassador;
                count = Math.min(100, Math.max(count, 10));

                console.log(`setting count to: ${count}, ambassadors: ${json.ambassador}`);

                this.spawner.setTargetCount(count);
            })
            .catch(err => console.error("error fetching ambassador count", err));
    }

}

export default FishTargetCountSetter;