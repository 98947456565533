import { Color3, IParticleSystem, ISceneLoaderAsyncResult, NoiseProceduralTexture, PBRMaterial, ShadowGenerator, Texture, Vector3 } from "@babylonjs/core";
import { IWaypoint } from "../data/IWaypoint";
import { MakeBubbles } from "../particles/BubblesParticleSystem";
import { MakeRays } from "../particles/RaysParticleSystem";
import { AmemoPrefab } from "./AmemoPrefab";
import { Prefab, PrefabLoader } from "./prefab";
import WaypointPrefab from "./waypoint-prefab";

export class FondalePrefab extends Prefab {
    // the edge of the ring is at -120
    // center is at 0,0,0
    // camera will look toward negative z

    caustic: NoiseProceduralTexture
    amemoPrefab: AmemoPrefab

    fishCenter = new Vector3(0, 0, 8);
    fishRadius = 60
    fishLimit?: IWaypoint
    
    fishMinHeight = 3
    get fishMaxHeight() { return 11; }

    get pagurosCenter() { return new Vector3(-2.7, -0.85, -2.41); }
    pagurosCenterWaypoint: IWaypoint
    pagurosSpawns: IWaypoint[]
    pagurosRadius = 9

    particleEmitters: IParticleSystem[]

    constructor(caustic: NoiseProceduralTexture, amemoPrefab: AmemoPrefab) {
        super();
        this.caustic = caustic;
        this.amemoPrefab = amemoPrefab;
    }

    init() {
        const scene = this.root.getScene();

        const pagurosCenter = this.pagurosCenter;

        const spawns: Vector3[] = [
            new Vector3(8, pagurosCenter.y, 6),
            new Vector3(10.67, pagurosCenter.y, 3.06),
            new Vector3(-16.15, pagurosCenter.y, -7.31),
        ];
        
        if (!PRODUCTION) {
            const waypointPrefab = new WaypointPrefab(scene);
            this.fishLimit = waypointPrefab.instantiate({position: new Vector3(0, this.fishMaxHeight, -110), name: "fish limit"});
            this.pagurosCenterWaypoint = waypointPrefab.instantiate({position: pagurosCenter, name: "paguros center"});
            this.pagurosSpawns = spawns.map((s, i) => waypointPrefab.instantiate({position: s, name: `paguro spawn ${i}`}));
        }
        else {
            this.pagurosSpawns = spawns.map(s => ({ position: s }));
        }

        const light = scene.lights[0];
        const shadowGenerator = light.getShadowGenerator() as ShadowGenerator;

        const childMeshes = this.root.getChildMeshes(); 

        for (const mesh of childMeshes) {
            if (!mesh.material)
                continue;

            (mesh.material as PBRMaterial).emissiveColor = Color3.FromHexString("#5ae7ee");
            (mesh.material as PBRMaterial).emissiveIntensity = 0.15;
            (mesh.material as PBRMaterial).emissiveTexture = this.caustic;

            mesh.receiveShadows = true;

            if (shadowGenerator) {
                if (mesh.name.startsWith("Alga")
                    || mesh.name.startsWith("Coral")
                    || mesh.name.startsWith("Playset")
                    // || mesh.name.startsWith("Sass")
                    // || mesh.name.startsWith("Sco")
                ) {
                    shadowGenerator.addShadowCaster(mesh);
                }
            }

            if (mesh.name.startsWith("Matte"))
                (mesh.material as PBRMaterial).albedoTexture = new Texture("assets/textures/Mattep.png", scene);
        }

        let i = 0;
        for (const node of childMeshes[0].getChildTransformNodes()) {
            if (node.name.startsWith("Empty_amemo")) {
                const instance = this.amemoPrefab.instantiate({parent: node.parent, position: node.position});
                
                if (i === 4 || i === 7 || i === 0 || i === 10)
                    instance.scaling = new Vector3(0.7, -0.7, 0.7);
                else if (i === 13)
                    instance.scaling = new Vector3(0.6, -0.6, 0.6);

                i++;
            }
        }

        this.particleEmitters = [
            MakeBubbles(scene, new Vector3(0, 0.5, -4)),
            MakeBubbles(scene, new Vector3(5, 0.5, 0)),
            MakeBubbles(scene, new Vector3(-10, 0.5, -5)),
            MakeBubbles(scene, new Vector3(-10.5, 0.5, 5)),
            MakeBubbles(scene, new Vector3(-15, 1.5, 5)),
            MakeBubbles(scene, new Vector3(-6, 0, 12)),
            MakeBubbles(scene, new Vector3(-1, 0, 13)),
            MakeRays(scene, new Vector3(45, 20, -70)),
            MakeRays(scene, new Vector3(-35, 20, -70)),
        ];
    }

    fixShadows() {
        for (const mesh of this.root.getChildMeshes()) {
            const mat = mesh.material;
            mesh.material = null;
            mesh.material = mat;
            // setTimeout(() => {
            // }, 1);
        }
    }
}

class DummyFondalePrefab extends FondalePrefab {
    constructor() {
        super(undefined, undefined);
    }
}

export class FondalePrefabLoader extends PrefabLoader<FondalePrefab> {
    path = "models/"
    filename = "Fondale_Set.glb"
    prefabConstructor = DummyFondalePrefab

    caustic: NoiseProceduralTexture
    amemoPrefab: AmemoPrefab
    
    inject(caustic: NoiseProceduralTexture, amemoPrefab: AmemoPrefab) {
        this.caustic = caustic;
        this.amemoPrefab = amemoPrefab;
        return this;
    }

    createPrefab(imported: ISceneLoaderAsyncResult, single: boolean) {
        return new FondalePrefab(this.caustic, this.amemoPrefab);
    }
}