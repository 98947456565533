import { AnimationGroup } from "@babylonjs/core";
import MoveRandomlyAtHeight from "./MoveRandomlyAtHeight";

class MoveRandomlyAtHeightWithBreaks extends MoveRandomlyAtHeight {
    breakProbability = 0.33
    breakMinMs = 600
    breakMaxMx = 2400

    moveAnim: AnimationGroup
    breakAnim: AnimationGroup
    resumeAnim: AnimationGroup

    constructor(moveAnim: AnimationGroup, breakAnim: AnimationGroup, resumeAnim: AnimationGroup) {
        super();

        this.moveAnim = moveAnim;
        this.breakAnim = breakAnim;
        this.resumeAnim = resumeAnim;
    }

    protected onReachedPosition() {
        if (Math.random() < this.breakProbability) {
            this.moveAnim.stop();
            this.breakAnim.play(false);
            this.breakAnim.onAnimationGroupEndObservable.addOnce(() => {
                const ms = Math.random() * (this.breakMaxMx - this.breakMinMs) + this.breakMinMs;
                setTimeout(() => {
                    this.resumeAnim.play(false);
                    this.resumeAnim.onAnimationGroupEndObservable.addOnce(() => {
                        this.moveAnim.play(true);
                        this.keepMoving();
                    });
                }, ms);
            });
        }
        else {
            this.keepMoving();
        }
    }
}

export default MoveRandomlyAtHeightWithBreaks;