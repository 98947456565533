import { Skeleton, Node, TransformNode } from "@babylonjs/core";

class SkeletonRetargeter {
    private overridenMeshIndex: number;
    private paths: number[][]

    constructor(skeleton: Skeleton, root: Node) {
        this.overridenMeshIndex = skeleton.overrideMesh.parent.getChildMeshes().indexOf(skeleton.overrideMesh);

        this.paths = [];

        for (const bone of skeleton.bones) {
            const target = bone.getTransformNode();
            const path: number[] = [];

            let node: Node = target;
            while (node.parent && node !== root) {
                const index = node.parent.getChildren().indexOf(node);
                path.push(index);
                node = node.parent;
            }

            path.reverse();

            this.paths.push(path);
        }
    }

    retarget(skeleton: Skeleton, targetRoot: Node) {
        if (skeleton.bones.length !== this.paths.length) {
            console.error(`skeleton has the wrong number of bones, expected ${this.paths.length} found ${skeleton.bones.length}`);
            return;
        }

        skeleton.overrideMesh = targetRoot.getChildMeshes()[this.overridenMeshIndex];

        for (let i = 0; i < this.paths.length; i++) {
            let node = targetRoot;

            for (const index of this.paths[i]) {
                node = node.getChildren()[index];
    
                if (!node)
                    break;
            }
    
            if (!node || !(node instanceof TransformNode))
                console.error("could not find target for bone", i, "in", targetRoot);
            else
                skeleton.bones[i].linkTransformNode(node);
        }
    }
}

export default SkeletonRetargeter;