import { BoxParticleEmitter, Color4, ConeParticleEmitter, ParticleHelper, ParticleSystem, Scene, Vector3 } from "@babylonjs/core";

export const MakeRays = (scene: Scene, position: Vector3, start = true) => {
    const particles = ParticleHelper.CreateDefault(position, 20, scene);
    const box = new BoxParticleEmitter();

    box.minEmitBox = new Vector3(-30, 0, 0);
    box.maxEmitBox = new Vector3(30, 0, 0);
    // box.direction1 = new Vector3(0.5, 0.5, 0);
    // box.direction2 = new Vector3(0.5, 0.5, 0);
    
    particles.particleEmitterType = box;
    particles.name = `Rays`;

    particles.blendMode = ParticleSystem.BLENDMODE_ADD;

    particles.emitRate = 15;

    particles.maxScaleX = 100;
    particles.maxScaleX = 120;
    particles.minScaleY = 500;
    particles.maxScaleY = 800;
    
    particles.minEmitPower = 0;
    particles.maxEmitPower = 0;

    particles.minInitialRotation = 0.6;
    particles.maxInitialRotation = 0.6;

    particles.minLifeTime = 2;
    particles.maxLifeTime = 4;

    particles.addColorGradient(0, new Color4(0,0,0,0));
    particles.addColorGradient(0.2, new Color4(1,1,1,0.1));
    particles.addColorGradient(0.8, new Color4(1,1,1,0.1));
    particles.addColorGradient(1, new Color4(0,0,0,0));

    // particles.addSizeGradient(0, 0.2, 0.4);
    // particles.addSizeGradient(1, 0);

    particles.updateSpeed = 0.007;

    particles.start();

    return particles;
};