import { AnimationGroup, IShadowLight, Mesh, Scene, ShadowGenerator, SimplificationType, StandardMaterial, TransformNode, Vector3 } from "@babylonjs/core";
import FollowWaypoints from "../behaviours/follow-waypoints";
import MoveRandomlyAtHeight from "../behaviours/MoveRandomlyAtHeight";
import { Prefab, PrefabLoader } from "./prefab";

export class FishPrefab extends Prefab {
    shadowSphere: Mesh

    init() {
        this.root.scaling = new Vector3(5, 5, 5);
        // this.shadowSphere = Mesh.CreateSphere("ellipse shadow", 4, 1, this.root.getScene(), false);
        // this.shadowSphere.scaling = this.shadowSphere.scaling.multiplyByFloats(1, 1, 2);
        // this.shadowSphere.position = new Vector3(0,0.1,20);
        // this.shadowSphere.material = new StandardMaterial("ellipse shadow", this.root.getScene());
        // this.shadowSphere.material.alpha = 0;
    }

    initInstance(instance: TransformNode, retargetedGroups: AnimationGroup[]) {
        // instance.addBehavior(new FollowWaypoints());
        instance.addBehavior(new MoveRandomlyAtHeight(), true);
        retargetedGroups[0].play(true);

        for (const mesh of instance.getChildMeshes())
            if (mesh instanceof Mesh && mesh.subMeshes)
                this.simplify(instance, mesh);

        // const sphere = this.shadowSphere.createInstance(`${instance.name}.Shadow`);
        // sphere.setParent(instance);
        // sphere.position = new Vector3(0, 0, 0);

        // const light = instance.getScene().lights[0] as IShadowLight;
        // const shadowGenerator = light.getShadowGenerator() as ShadowGenerator;
        // if (shadowGenerator)
        //     shadowGenerator.addShadowCaster(sphere);

        Prefab.randomlyScale(instance, 0.5);
    }

    simplify(instance: TransformNode, mesh: Mesh) {
        // const farMesh = Mesh.CreateSphere("lod", 4, 1, mesh.getScene(), false);
        // farMesh.setParent(mesh.parent);
        // farMesh.material = mesh.material;
        // farMesh.position = Vector3.Zero();

        // const camera = mesh.getScene().activeCamera.parent as TransformNode;

        // setInterval(() => {
        //     const isFar = Vector3.DistanceSquared(camera.position, instance.position) > 100;
        //     mesh.setEnabled(!isFar);
        //     farMesh.setEnabled(isFar);
        // }, 500);

        // mesh.getScene().onBeforeRenderObservable.add(() => {
        //     console.log(`distance from ${mesh.name}`, mesh.getDistanceToCamera());
        // });
        // mesh.forceSharedVertices();
        // mesh.simplify(
        //     [
        //         { quality: 0.1, distance: 30 },
        //         // { quality: 0.05, distance: 20 },
        //     ],
        //     false,
        //     SimplificationType.QUADRATIC,
        //     () => {
        //         console.log("simplification done");
                
        //         // doc says this callback should receive parameters but they're always undefined...

        //         // let's assume the simplified meshes have no skeleton
        //         const simplified = mesh.parent.getChildMeshes().filter(m => !m.skeleton);

        //         // assign the skeleton to the simplified meshes from the mesh with the same material
        //         for (const simple of simplified) {
        //             for (const complex of mesh.parent.getChildMeshes()) {
        //                 if (complex.material === simple.material) {
        //                     simple.skeleton = complex.skeleton;
        //                     break;
        //                 }
        //             }
        //         }
        //     }
        // );
    }
}

export class FishLoader extends PrefabLoader<FishPrefab> {
    path = "models/fish_01/"
    filename = "Fish_01.glb"
    prefabConstructor = FishPrefab
};