import { Behavior, LinesMesh, MeshBuilder, TransformNode, Vector3 } from '@babylonjs/core'
import { IWaypoint } from '../data/IWaypoint';

class Waypoint implements Behavior<TransformNode>, IWaypoint {
    name = "Waypoint"
    transform: TransformNode
    dbgLine: LinesMesh
    private _next: Waypoint

    get position() {
        return this.transform.position;
    }

    get next() {
        return this._next;
    }

    set next(waypoint) {
        this._next = waypoint;

        if (!PRODUCTION) {
            this.dbgLine = MeshBuilder.CreateLines(
                this.transform.name + ".TrackingLine", 
                {
                    points: [this.transform.position, this.nextPosition],
                    instance: this.dbgLine,
                }
            );
        }
    }

    private get nextPosition() {
        return this._next ? this._next.transform.position : this.transform.position.add(new Vector3(0,0,1));
    }

    init(): void {
    }

    attach(transform: TransformNode): void {
        this.transform = transform;

        if (!PRODUCTION) {
            import("@babylonjs/gui").then(({AdvancedDynamicTexture, Button}) => {
                const sphere = MeshBuilder.CreateSphere("waypoint-sphere", { diameter: 0.5 }, transform.getScene());
                sphere.setParent(transform);
                sphere.scaling = new Vector3(-1, -1, 1);
                sphere.position = new Vector3(0, 0, 0);
                
                const advancedTexture = AdvancedDynamicTexture.CreateForMesh(sphere);
                const btn = Button.CreateSimpleButton("btn", transform.name);
                btn.width = 1;
                btn.height = 0.4;
                btn.color = "white";
                btn.fontSize = 180;
                btn.background = "red";
                advancedTexture.addControl(btn);
    
                this.dbgLine = MeshBuilder.CreateLines(transform.name + ".TrackingLine", { points: [transform.position, this.nextPosition], updatable: true, }, this.transform.getScene());
                this.dbgLine.setParent(transform);
            })
        }
    }

    detach(): void {
    }
}

export default Waypoint;