import "@babylonjs/loaders/glTF";
import { Database, Engine, Scene, ShadowGenerator, ShadowGeneratorSceneComponent, Sound, Vector3 } from "@babylonjs/core";
import Environment from "./prefabs/environment";
import RiomareCamera from "./prefabs/riomare-camera";
import { FishLoader } from "./prefabs/fish-prefab";
import BehaviorsUtils from "./behaviours/utils";
import FollowWaypoints from "./behaviours/follow-waypoints";
import { PaguroPrefabLoader } from "./prefabs/paguro-prefab";
import FishSpawner from "./managers/FishSpawner";
import { FondalePrefabLoader } from "./prefabs/FondalePrefab";
import { ISpawnPoint } from "./data/ISpawnPoint";
import CustomLoadingScreen from "./managers/CustomLoadingScreen";
import { Fish2Loader } from "./prefabs/Fish2Prefab";
import { Fish3Loader } from "./prefabs/Fish3Prefab";
import { CavallucLoader } from "./prefabs/CavallucPrefab";
import { DolphLoader } from "./prefabs/DolphPrefab";
import { FondaleCerchioPrefabLoader } from "./prefabs/FondaleCerchioPrefab";
import { DefaultCausticTextureParameters, MakeCausticTexture } from "./textures/CausticTexture";
import { AmemoPrefabLoader } from "./prefabs/AmemoPrefab";
import { JellyfishPrefabLoader } from "./prefabs/JellyfishPrefab";
import UserAgentHelper from "./helpers/UserAgentHelper";
import FishTargetCountSetter from "./managers/FishTargetCountSetter";

class App {
    constructor() {
        window.addEventListener('load', async (event) => {
            Database.IDBStorageEnabled = true;

            var canvas = document.getElementById("babylon") as HTMLCanvasElement;

            // initialize babylon scene and engine
            var engine = new Engine(canvas, true);
            var scene = new Scene(engine);

            Engine.audioEngine.useCustomUnlockedButton = true;
            canvas.addEventListener("click", () => {
                Engine.audioEngine.unlock();
            })

            scene.disablePhysicsEngine();
            scene.collisionsEnabled = false;
            scene.shadowsEnabled = !UserAgentHelper.IsMobile();

            const camera = new RiomareCamera(scene, canvas);
            
            const env = new Environment(scene);

            if (scene.shadowsEnabled) {
                const shadowGenerator = new ShadowGenerator(2048, env.light);
                shadowGenerator.setTransparencyShadow(true);
                shadowGenerator.darkness = 0.2;
                shadowGenerator.usePoissonSampling = false; // better shadows but slower
                // shadowGenerator.useExponentialShadowMap = true; // false is performance boost
                // shadowGenerator.blurScale = 1;
                // shadowGenerator.useBlurExponentialShadowMap = true;
                // shadowGenerator.blurBoxOffset = 4;
                // shadowGenerator.depthScale = 100;
            }

            engine.loadingScreen = new CustomLoadingScreen(
                canvas,
                document.getElementById("loading"),
            );

            // resize
            const onResize = () => {
                engine.resize();
                const loadingNode = $((engine.loadingScreen as CustomLoadingScreen).htmlNode);
                loadingNode.width(canvas.width);
                loadingNode.height(canvas.height);
            };
            $(window).on("resize", onResize);
            onResize();

            engine.displayLoadingUI();

            const caustic = MakeCausticTexture(scene);
            const stretchedCausting = MakeCausticTexture(scene, {
                ...DefaultCausticTextureParameters,
                horizontalScale: 2,
                verticalScale: 1,
            });

            const [fondalePrefab, cerchioPrefab] = await Promise.all([
                new AmemoPrefabLoader().import(scene)
                    .then(amemoPrefab => new FondalePrefabLoader().inject(caustic, amemoPrefab).import(scene, true)),
                new FondaleCerchioPrefabLoader().inject(stretchedCausting).import(scene, true),
            ]);
            
            engine.hideLoadingUI();

            // run the main render loop
            engine.runRenderLoop(() => {
                scene.render();
            });

            new Sound("fishtank", "assets/sounds/fishTankLoop.mp3", scene, null, {
                loop: true,
                autoplay: true,
            });

            new Sound("cantina", "assets/sounds/Cantina_Full_Track.mp3", scene, null, {
                loop: true,
                autoplay: true,
                volume: 0.2,
            });

            const prefabs = await Promise.all([
                new FishLoader().import(scene),
                new Fish2Loader().import(scene),
                new Fish3Loader().import(scene),
                new CavallucLoader().import(scene),
                new DolphLoader().import(scene),
                new JellyfishPrefabLoader().import(scene),
                new PaguroPrefabLoader().import(scene),
            ]);

            const fishes = prefabs.slice(0, prefabs.length - 1);
            const paguroPrefab = prefabs[prefabs.length - 1];

            const spawner = new FishSpawner([
                ...fishes.map(fish => ({
                    prefab: fish,
                    spawnPoints: camera.spawns.map(s => ({
                        get position() { return s.position; },
                        movementCenter: fondalePrefab.fishCenter,
                        maxDistance: fondalePrefab.fishRadius,
                        maxRadians: Math.PI,
                        minHeight: fondalePrefab.fishMinHeight,
                        maxHeight: fondalePrefab.fishMaxHeight,
                    })),
                    probability: 0.9 / fishes.length,
                })),
                {
                    prefab: paguroPrefab,
                    spawnPoints: fondalePrefab.pagurosSpawns.map(ps => ({
                        position: ps.position,
                        movementCenter: fondalePrefab.pagurosCenter,
                        maxDistance: fondalePrefab.pagurosRadius,
                        fixedHeight: true,
                        maxRadians: Math.PI * 2,
                    })),
                    probability: 0.1,
                },
            ]);

            for (let i = 0; i < 10; i++)
                spawner.spawn();

            // fondalePrefab.fixShadows();
            new FishTargetCountSetter(spawner);

            // hide/show the Inspector
            window.addEventListener("keydown", (ev) => {
                // Shift+Ctrl+Alt+I
                if (ev.shiftKey && ev.ctrlKey && ev.altKey && ev.keyCode === 73) {
                    if (scene.debugLayer.isVisible()) {
                        scene.debugLayer.hide();
                    } else {
                        scene.debugLayer.show();
                    }
                }
            });

            if (!PRODUCTION) {
                Promise.all([
                    import("@babylonjs/core/Debug/debugLayer"),
                    import("@babylonjs/inspector"),
                ])
                .then(() => {
                    // scene.debugLayer.show();
                });

                const fpsDiv = document.getElementById("fps");

                scene.onBeforeRenderObservable.add(() => {
                    fpsDiv.innerText = `${engine.getFps().toPrecision(2)}`;
                })
            }
        });
    }
}
new App();