import { Animation, AnimationGroup, Node, TargetedAnimation } from "@babylonjs/core";

class RetargetableAnimation {
    animation: Animation
    path: number[]

    constructor(targeted: TargetedAnimation, root: Node = null) {
        this.animation = targeted.animation;
        this.path = [];

        let node = targeted.target as Node;
        while (node.parent && node !== root) {
            const index = node.parent.getChildren().indexOf(node);
            this.path.push(index);
            node = node.parent;
        }

        this.path.reverse();
    }

    retarget(targetRoot: Node, group: AnimationGroup) {
        let node = targetRoot;

        for (const index of this.path) {
            node = node.getChildren()[index];

            if (!node)
                break;
        }

        if (!node)
            console.error("could not find target for", this.animation, "in", targetRoot);
        else
            group.addTargetedAnimation(this.animation, node);
    }
}

export default RetargetableAnimation;