import { Behavior, Matrix, TransformNode, Vector3 } from "@babylonjs/core";
import MoveToPoint from "./MoveToPoint";
import BehaviorsUtils from "./utils";

class MoveRandomlyAtHeight implements Behavior<TransformNode> {
    name = "FollowWaypoints"
    height: number
    center: Vector3
    maxDistance: number
    maxRadians: number
    private _movement: MoveToPoint

    get minSpeed() {
        return this._movement.minSpeed;
    }

    set minSpeed(val) {
        this._movement.minSpeed = val;
    }

    get maxSpeed() {
        return this._movement.maxSpeed;
    }

    set maxSpeed(val) {
        this._movement.maxSpeed = val;
    }

    startMoving(height: number, center: Vector3, maxDistance = 50, maxRadians = Math.PI * 2) {
        this.height = height;
        this.center = center;
        this.maxDistance = maxDistance;
        this.maxRadians = maxRadians;

        const pos = this._movement.transform.position.clone();
        pos.y = this.height;
        this._movement.transform.position = pos;

        this.keepMoving();
    }
    
    protected keepMoving() {
        const radians = Math.random() * this.maxRadians;
        const matrix = Matrix.RotationAxis(Vector3.Up(), radians);
        matrix.setTranslation(this.center);

        const point = this.center.clone();
        point.x = Math.random() * this.maxDistance;

        const rotated = Vector3.TransformCoordinates(point, matrix);
        rotated.y = this.height;

        this._movement.goTo(rotated, this.onReachedPosition.bind(this));
    }

    protected onReachedPosition() {
        this.keepMoving();
    }

    init(): void {
    }

    attach(target: TransformNode): void {
        this._movement = BehaviorsUtils.getOrAddBehavior(target, MoveToPoint);
    }

    detach(): void {
    }

}

export default MoveRandomlyAtHeight;
