import { NoiseProceduralTexture, Scene } from "@babylonjs/core";

interface CausticTextureParameters {
    size: number;
    octaves: number;
    persistence: number;
    speedFactor: number;
    horizontalScale: number;
    verticalScale: number;
}

export const DefaultCausticTextureParameters: CausticTextureParameters = {
    size: 64,
    octaves: 6,
    persistence: 1.33,
    speedFactor: 3,
    horizontalScale: 1,
    verticalScale: 1,
}

export const MakeCausticTexture = (scene: Scene, parameters: CausticTextureParameters = DefaultCausticTextureParameters) => {
    const noiseTexture = new NoiseProceduralTexture("perlin", parameters.size, scene);

    noiseTexture.octaves = parameters.octaves;
    noiseTexture.persistence = parameters.persistence;
    noiseTexture.animationSpeedFactor = parameters.speedFactor;
    noiseTexture.uScale = parameters.verticalScale;
    noiseTexture.vScale = parameters.horizontalScale;

    return noiseTexture;
}