import { Scene, TransformNode } from "@babylonjs/core";
import Waypoint from "../behaviours/waypoint";
import { InstantiatePrefabArgs, Prefab } from "./prefab";

class WaypointPrefab {
    scene: Scene

    constructor(scene: Scene) {
        this.scene = scene;
    }

    instantiate(args : InstantiatePrefabArgs = {}) {
        args.name = args.name || "WaypointClone";
        
        const instance = new TransformNode(args.name, this.scene);
        Prefab.setIntantiationArgs(instance, args);
        const waypoint = new Waypoint();
        instance.addBehavior(waypoint);

        return waypoint;
    }
}

export default WaypointPrefab;