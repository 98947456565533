import { AnimationGroup, Node } from "@babylonjs/core";
import RetargetableAnimation from "./RetargetableAnimation";

class AnimationsRetargeter {
    private srcName: string
    private retargetables: RetargetableAnimation[]

    constructor(group: AnimationGroup, root: Node = null) {
        this.srcName = group.name;
        this.retargetables = AnimationsRetargeter.extractRetargetables(group, root);
    }

    retarget(target: Node, name?: string) {
        const group = new AnimationGroup(name || `${target.name}.${this.srcName}`);

        for (const retargetable of this.retargetables)
            retargetable.retarget(target, group);

        return group;
    }

    static extractRetargetables(group: AnimationGroup, root: Node = null) {
        const retargetables: RetargetableAnimation[] = [];

        for (const targeted of group.targetedAnimations)
            retargetables.push(new RetargetableAnimation(targeted, root));

        return retargetables;
    }

}

export default AnimationsRetargeter;