import { ILoadingScreen } from "@babylonjs/core";

class CustomLoadingScreen implements ILoadingScreen {
    loadingUIBackgroundColor: "black";
    loadingUIText = "loading...";

    canvas: HTMLCanvasElement
    htmlNode: HTMLElement

    constructor(canvas: HTMLCanvasElement, htmlNode: HTMLElement) {
        this.canvas = canvas;
        this.htmlNode = htmlNode;
    }

    displayLoadingUI() {
        this.htmlNode.style.display = "flex";
    }

    hideLoadingUI() {
        const ms = 1400;
        $(this.canvas).hide();
        $(this.canvas).fadeIn(ms);
        setTimeout(() => {
            $(this.htmlNode).hide();
        }, ms + 500);
    }
}

export default CustomLoadingScreen;