import { AnimationGroup, IShadowLight, Mesh, ShadowGenerator, StandardMaterial, TransformNode, Vector3 } from "@babylonjs/core";
import FollowWaypoints from "../behaviours/follow-waypoints";
import MoveRandomlyAtHeightWithBreaks from "../behaviours/MoveRandomlyAtHeightWithBreaks";
import { Prefab, PrefabLoader } from "./prefab";

export class PaguroPrefab extends Prefab {
    init() {
        this.root.scaling = new Vector3(5, 5, 5);
    }

    initInstance(instance: TransformNode, retargetedGroups: AnimationGroup[]) {
        // instance.addBehavior(new FollowWaypoints());
        const move = new MoveRandomlyAtHeightWithBreaks(retargetedGroups[2], retargetedGroups[0], retargetedGroups[1]);
        instance.addBehavior(move, true);
        
        move.minSpeed = 0.6;
        move.maxSpeed = 1;

        retargetedGroups[2].play(true);

        // for (const mesh of instance.getChildMeshes())
        //     mesh.receiveShadows = true;

        const sphere = Mesh.CreateSphere("sphere shadow", 4, 0.8, instance.getScene(), false);
        sphere.setParent(instance);
        // sphere.scaling = sphere.scaling.multiplyByFloats(1, 1, 2);
        sphere.position = new Vector3(0,0.1,0);
        sphere.material = new StandardMaterial("sphere shadow", instance.getScene());
        sphere.material.alpha = 0;

        const light = instance.getScene().lights[0] as IShadowLight;
        
        const shadowGenerator = light.getShadowGenerator() as ShadowGenerator;
        if (shadowGenerator) {
            shadowGenerator.addShadowCaster(sphere);
            // shadowGenerator.addShadowCaster(instance.getChildMeshes()[1]);
        }

        Prefab.randomlyScale(instance);
    }
};

export class PaguroPrefabLoader extends PrefabLoader<PaguroPrefab> {
    path = "models/"
    filename = "paguro.glb"
    prefabConstructor = PaguroPrefab
};