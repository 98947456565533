import { Color3, ISceneLoaderAsyncResult, Material, NoiseProceduralTexture, PBRMaterial, Texture, Vector3 } from "@babylonjs/core";
import { Prefab, PrefabLoader } from "./prefab";

export class FondaleCerchioPrefab extends Prefab {
    caustic: NoiseProceduralTexture
    
    constructor(caustic: NoiseProceduralTexture) {
        super();
        this.caustic = caustic;
    }

    init() {
        // this.root.scaling = new Vector3(1, -1, 1);
        this.root.position = new Vector3(0, 0, 11);

        for (const mesh of this.root.getChildMeshes()) {
            if (!mesh.material)
                continue;

            (mesh.material as PBRMaterial).emissiveColor = Color3.FromHexString("#5ae7ee");
            (mesh.material as PBRMaterial).emissiveIntensity = 0.03;
            (mesh.material as PBRMaterial).emissiveTexture = this.caustic;
            
            if (mesh.name.startsWith("Matte")) {
                // (mesh.material as PBRMaterial).albedoTexture = new Texture("assets/textures/Mattep.png", this.root.getScene());
                mesh.material.transparencyMode = Material.MATERIAL_OPAQUE;
            }
        }
    }
}

class DummyFondaleCerchioPrefab extends FondaleCerchioPrefab {
    constructor() {
        super(undefined);
    }
}

export class FondaleCerchioPrefabLoader extends PrefabLoader<FondaleCerchioPrefab> {
    path = "models/"
    filename = "matte.glb"
    prefabConstructor = DummyFondaleCerchioPrefab
    caustic: NoiseProceduralTexture

    inject(caustic: NoiseProceduralTexture) {
        this.caustic = caustic;
        return this;
    }

    createPrefab(imported: ISceneLoaderAsyncResult, single: boolean) {
        return new FondaleCerchioPrefab(this.caustic);
    }
}