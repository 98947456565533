import { Vector3 } from "@babylonjs/core";
import { FishPrefab } from "./fish-prefab"
import { PrefabLoader } from "./prefab"

export class DolphPrefab extends FishPrefab {
    init() {
        super.init();
        this.root.scaling = new Vector3(15, 15, 15);
    }
}

export class DolphLoader extends PrefabLoader<DolphPrefab> {
    path = "models/fish_01/"
    filename = "dolph.glb"
    prefabConstructor = DolphPrefab
};