import { TransformNode } from "@babylonjs/core";
import FollowWaypoints from "../behaviours/follow-waypoints";
import MoveRandomlyAtHeight from "../behaviours/MoveRandomlyAtHeight";
import BehaviorsUtils from "../behaviours/utils";
import { IFishSpawn } from "../data/IFishSpawn";

interface SpawnerConfig {
    ms: number;
    batch: number;
}

interface SpawnerConfigArg {
    ms?: number;
    batch?: number;
}

class FishSpawner {
    defaultSpawnConfig: SpawnerConfig = {
        ms: 15000,
        batch: 3,
    }

    defaultDestroyConfig: SpawnerConfig = {
        ms: 2000,
        batch: 1,
    }
    
    private _spawns: IFishSpawn[]
    private _probabilitySum: number
    private _fishes: TransformNode[] = []
    private _targetCount = 0

    private _schedules : {
        spawn?: ReturnType<typeof setTimeout>;
        destroy?: ReturnType<typeof setTimeout>;
    } = {}

    get targetCount() {
        return this._targetCount;
    }

    get fishCount() {
        return this._fishes.length;
    }

    constructor(spawns: IFishSpawn[]) {
        this._spawns = spawns;

        this._probabilitySum = 0;
        for (const s of spawns)
            this._probabilitySum += s.probability;
    }

    setTargetCount(count: number, config?: SpawnerConfig) {
        if (this._targetCount === count)
            return;
        
        this._targetCount = count;

        if (this.fishCount === count) {
            clearTimeout(this._schedules.spawn);
            clearTimeout(this._schedules.destroy);
            return;
        }

        
        if (this.fishCount < count) {
            clearTimeout(this._schedules.destroy);

            config = {
                ms: config?.ms ?? this.defaultSpawnConfig.ms,
                batch: config?.batch ?? this.defaultSpawnConfig.batch,
            };

            this._scheduleSpawn(config);
        }
        else {
            clearTimeout(this._schedules.spawn);

            config = {
                ms: config?.ms ?? this.defaultDestroyConfig.ms,
                batch: config?.batch ?? this.defaultDestroyConfig.batch,
            };

            this._scheduleDestroy(config);
        }
    }

    spawn() {
        const selectedSpawn = this.getRandomSpawn();

        const randomIndex = Math.floor(Math.random() * selectedSpawn.spawnPoints.length);
        const selectedPoint = selectedSpawn.spawnPoints[randomIndex];

        const instance = selectedSpawn.prefab.instantiate({
            name: `${selectedSpawn.prefab.root.name}Clone${this.fishCount}`,
            position: selectedPoint.position,
        });

        const follower = BehaviorsUtils.getBehavior(instance, FollowWaypoints);
        if (follower) {
            follower.follow(selectedPoint.waypoint);
        }
        else {
            const mover = BehaviorsUtils.getBehavior(instance, MoveRandomlyAtHeight);

            let height = selectedPoint.movementCenter.y;

            if (!selectedPoint.fixedHeight)
                height = Math.random() * (selectedPoint.maxHeight - selectedPoint.minHeight) + selectedPoint.minHeight;

            mover.startMoving(height, selectedPoint.movementCenter, selectedPoint.maxDistance, selectedPoint.maxRadians);
        }

        this._fishes.push(instance);
    }

    destroy(count: number) {
        count = Math.min(this.fishCount, count);
        if (count > 0) {
            const removed = this._fishes.splice(0, count);

            for (const fish of removed)
                fish.dispose();
        }
    }

    private getRandomSpawn() {
        const random = Math.random() * this._probabilitySum;

        let accumulated = 0;
        for (const spawn of this._spawns) {
            if (random < spawn.probability + accumulated)
                return spawn;
            accumulated += spawn.probability;
        }

        return this._spawns[0];
    }

    private _scheduleSpawn(config: SpawnerConfig) {
        if (this._schedules.spawn)
            return;
        
        this._schedules.spawn = setTimeout(() => {
            this._schedules.spawn = undefined;

            const count = Math.min(config.batch, this._targetCount - this.fishCount);
            for (let i = 0; i < count; i++)
                this.spawn();

            if (this.fishCount < this._targetCount)
                this._scheduleSpawn(config);
            
        }, config.ms);
    }

    private _scheduleDestroy(config: SpawnerConfig) {
        if (this._schedules.destroy)
            return;

        this._schedules.destroy = setTimeout(() => {
            this._schedules.destroy = undefined;

            const count = Math.min(config.batch, this.fishCount - this._targetCount);
            this.destroy(count);

            if (this.fishCount > this._targetCount)
                this._scheduleDestroy(config);

        }, config.ms);
    }
}

export default FishSpawner;