import { Angle, PointerEventTypes, Scene, TransformNode, UniversalCamera, Vector3 } from "@babylonjs/core";
import { IWaypoint } from "../data/IWaypoint";

class RiomareCamera {
    camera: UniversalCamera
    container: TransformNode
    spawns: IWaypoint[]

    constructor(scene: Scene, canvas: HTMLCanvasElement) {
        this.container = new TransformNode("camera container", scene);
        this.container.position = new Vector3(-3.23,3.26, 15.39);
        this.container.rotation = new Vector3(0, Angle.FromDegrees(-180 - 5).radians(), 0);

        let camera = new UniversalCamera("Camera", new Vector3(0, 0, 0), scene);
        camera.attachControl(canvas, true);
        camera.parent = this.container;

        const constrain = true;

        // forbid movement
        if (constrain)
            camera.speed = 0;

        // camera.touchAngularSensibility /= 10;
        camera.touchAngularSensibility = 10000;

        console.log("sensitivities", camera.touchAngularSensibility, camera.angularSensibility);

        const leftSpawn = new TransformNode("behind camera spawn left");
        leftSpawn.setParent(camera);
        leftSpawn.position = new Vector3(-30, 0, 5);

        const rightSpawn = new TransformNode("behind camera spawn right");
        rightSpawn.setParent(camera);
        rightSpawn.position = new Vector3(30, 0, 5);

        const zero = Vector3.Zero();

        this.spawns = [
            { get position() {  return Vector3.TransformCoordinates(zero, leftSpawn.computeWorldMatrix()); } },
            { get position() {  return Vector3.TransformCoordinates(zero, rightSpawn.computeWorldMatrix()); } },
        ];

        camera.checkCollisions = false;

        if (constrain) {
            camera.inputs.clear();

            const yBound = Math.PI / 5.5;
            const fixedRotationX = Angle.FromDegrees(7.2).radians();
            camera.rotation.x = fixedRotationX;

            const dbgDiv = document.getElementById("dbg");

            let last = {x:0, y:0};
            let isMouseDown = false;

            scene.onPointerObservable.add((pointerInfo) => {
                switch (pointerInfo.type) {
                    case PointerEventTypes.POINTERDOWN:
                        isMouseDown = true;
                        last = {x: pointerInfo.event.clientX, y: pointerInfo.event.clientY };
                        break;
                    case PointerEventTypes.POINTERUP:
                        isMouseDown = false;
                        break;
                    case PointerEventTypes.POINTERMOVE:
                        if (isMouseDown) {
                            move({x: pointerInfo.event.clientX - last.x, y: pointerInfo.event.clientY - last.y});
                            last = {x: pointerInfo.event.clientX, y: pointerInfo.event.clientY };
                        }
                }
            });

            const maxSensibilityMultiplier = 0.5;
            const minSensibilityMultiplier = 0;
            const sensibilityRange = maxSensibilityMultiplier - minSensibilityMultiplier;

            function move(delta: {x: number, y: number}) {
                const rad = (delta.x / 10) * Math.PI/180;

                if (!PRODUCTION)
                    dbgDiv.innerText = `${delta.x} ${delta.y} rad: ${rad.toPrecision(2)}`;
                
                let multiplier = maxSensibilityMultiplier;
                
                // lerp multiplier to avoid sudden stop
                if ((camera.rotation.y < 0 && delta.x < 0) || (camera.rotation.y > 0 && delta.x > 0)) {
                    const absY = Math.abs(camera.rotation.y);
                    const ratioY = 1 - (absY/yBound);
                    multiplier = ratioY * sensibilityRange + minSensibilityMultiplier

                    if (!PRODUCTION)
                        dbgDiv.innerText += `\ncam rot: ${camera.rotation.y} \nratio: ${ratioY} \nmult: ${multiplier}`;
                }

                camera.rotation.y += rad * multiplier;
            }
        }

        this.camera = camera;
    }
    
}

export default RiomareCamera;
