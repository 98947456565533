import { Behavior, Node } from "@babylonjs/core";

class BehaviorsUtils {
    static getBehavior<T extends Behavior<Node>>(node: Node, type: { new (...args: any[]): T }): T  {
        for (const b of node.behaviors) {
            if (b instanceof type)
                return b;
        }
        return undefined;
    }

    static getOrAddBehavior<T extends Behavior<Node>>(node: Node, type: { new (...args: any[]): T }): T  {
        let behavior = BehaviorsUtils.getBehavior(node, type);

        if (behavior === undefined) {
            behavior = new type();
            node.addBehavior(behavior, true);
        }

        return behavior;
    }
}

export default BehaviorsUtils;