import { Animatable, Animation, Behavior, Quaternion, TransformNode, Vector3 } from "@babylonjs/core";

class MoveToPoint implements Behavior<TransformNode> {
    name = "MoveToPoint"
    transform: TransformNode
    minSpeed = 1
    maxSpeed = 2

    private _anims: Animatable[]

    goTo(destination: Vector3, reachedCallback: () => void = () => {}) {
        for (const anim of this._anims)
            if (anim)
                anim.stop();

        this._anims = [];

        const currentRotation = this.transform.rotationQuaternion.clone();
        this.transform.lookAt(destination);
        const finalRotation = this.transform.rotationQuaternion.clone();
        this.transform.rotationQuaternion = currentRotation.clone();

        const distance = destination.subtract(this.transform.position).length();

        if (distance === 0) {
            reachedCallback();
            return;
        }

        const speed = Math.random() * (this.maxSpeed - this.minSpeed) + this.minSpeed;

        this._anims.push(Animation.CreateAndStartAnimation(
            this.transform.name + ".MoveToPosition",
            this.transform,
            "position",
            60,
            60 * distance / speed,
            this.transform.position,
            destination,
            0
        ));

        this._anims.push(Animation.CreateAndStartAnimation(
            this.transform.name + ".MoveToRotation",
            this.transform,
            "rotationQuaternion",
            60,
            20,
            currentRotation,
            finalRotation,
            0
        ));

        this._anims[0].onAnimationEndObservable.add(reachedCallback);
    }

    init(): void {
        this._anims = [];
    }

    attach(target: TransformNode): void {
        this.transform = target;
        this.transform.rotationQuaternion = Quaternion.Identity();
    }

    detach(): void {
        for (const anim of this._anims)
            if (anim)
                anim.stop();
    }
}

export default MoveToPoint;