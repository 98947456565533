import { AnimationGroup, InstantiatedEntries, ISceneLoaderAsyncResult, Mesh, Node, Scene, SceneLoader, TransformNode, Vector3 } from "@babylonjs/core";
import AnimationsRetargeter from "../helpers/AnimationsRetargeter";
import SkeletonRetargeter from "../helpers/SkeletonRetargeter";

export interface InstantiatePrefabArgs {
    name?: string;
    parent?: Node;
    position?: Vector3;
    enabled?: boolean;
}

export class Prefab {
    root: TransformNode
    animationRetargeters: AnimationsRetargeter[]
    skeletonRetargeter: SkeletonRetargeter
    imported: ISceneLoaderAsyncResult

    import(imported : ISceneLoaderAsyncResult, single: boolean) {
        this.imported = imported;

        if (imported.meshes.length > 1) {
            this.root = new TransformNode(this.getName(), imported.meshes[0].getScene());
            for (const mesh of imported.meshes) {
                mesh.setParent(this.root);
                mesh.doNotSyncBoundingInfo = true;
            }
        }
        else {
            imported.meshes[0].doNotSyncBoundingInfo = true;
            this.root = imported.meshes[0];
            this.root.name = this.getName();
        }

        // slightly improves performance
        for (const mesh of imported.meshes) {
            if (mesh.material) {
                mesh.material.freeze();
            }
        }

        this.animationRetargeters = imported.animationGroups.map(g => new AnimationsRetargeter(g, this.root));

        for (const group of imported.animationGroups)
            group.stop();

        if (imported.skeletons.length > 0)
            this.skeletonRetargeter = new SkeletonRetargeter(imported.skeletons[0], this.root);

        // this improves performance but the textures are all black
        // if (imported.meshes.length > 1) {
        //     this.root = new TransformNode(this.getName(), imported.meshes[0].getScene());
        //     for (const mesh of imported.meshes) {
        //         const instance = (mesh as Mesh).createInstance(`${mesh.name}-instance`);
        //         instance.setParent(this.root);
        //         mesh.setEnabled(false);
        //     }
        // }
        // else {
        //     this.root = (imported.meshes[0] as Mesh).createInstance(this.getName());
        // }

        this.init();

        if (!single)
            this.root.setEnabled(false);

        return this;
    }

    getName() {
        return this.constructor.name;
    }

    init() {}

    instantiate(args : InstantiatePrefabArgs = {}) {
        args.name = args.name || this.root.name + "Clone";

        this.root.setEnabled(true);
        const instance = this.root.clone(args.name, this.root.parent);
        this.root.setEnabled(false);

        for (const child of instance.getChildren((n => n instanceof Mesh))) {
            const mesh = child as Mesh;
            // without setting this meshes disappear too early when the camera rotates
            mesh.alwaysSelectAsActiveMesh = true;

            // this actually makes performance worse, i guess because each mesh is different
            // so by instantiating we're actually just adding more stuff to the scene
            // mesh.createInstance(`${mesh.name}.Instance`).setParent(mesh.parent);
            // mesh.setEnabled(false);
        }

        const retargetedGroups = this.animationRetargeters.map(ar => ar.retarget(instance));

        const instanceSkeleton = this.imported.skeletons[0].clone(`${args.name}.skelly`);
        
        for (const mesh of instance.getChildMeshes())
            mesh.skeleton = instanceSkeleton;

        this.skeletonRetargeter.retarget(instanceSkeleton, instance);

        Prefab.setIntantiationArgs(instance, args);

        this.initInstance(instance, retargetedGroups);

        return instance;
    }

    initInstance(instance: TransformNode, retargetedGroups: AnimationGroup[]) {}

    static setIntantiationArgs(instance: TransformNode, args: InstantiatePrefabArgs) {
        if (args.name)
            instance.name = args.name;

        if (parent)
            instance.setParent(args.parent);

        if (args.position)
            instance.position = args.position;
        
        instance.setEnabled(args.enabled ?? true);
    }

    static randomlyScale(instance: TransformNode, minScale = 0.8, maxScale = 1.2) {
        const factor = Math.random() * (maxScale - minScale) + minScale;
        instance.scaling = instance.scaling.multiplyByFloats(factor, factor, factor);
    }
}

export abstract class PrefabLoader<T extends Prefab> {
    assetRoot: string
    abstract path: string
    abstract filename: string
    abstract prefabConstructor: { new (): T; }

    constructor(assetRoot = "assets/") {
        this.assetRoot = assetRoot;
    }

    createPrefab(imported: ISceneLoaderAsyncResult, single: boolean) {
        return new this.prefabConstructor();
    }

    async import(scene: Scene, single = false) : Promise<T> {
        const result = await SceneLoader.ImportMeshAsync("", this.assetRoot + this.path, this.filename, scene);
        const prefab = this.createPrefab(result, single);
        return prefab.import(result, single);
    }
}