import { FishPrefab } from "./fish-prefab";
import { PrefabLoader } from "./prefab";

export class JellyfishPrefab extends FishPrefab {
    
}

export class JellyfishPrefabLoader extends PrefabLoader<JellyfishPrefab> {
    path = "models/"
    filename = "JellyFish.glb"
    prefabConstructor = JellyfishPrefab
}