import { Color3, DirectionalLight, HemisphericLight, Mesh, MeshBuilder, Scene, Vector3 } from "@babylonjs/core";

class Environment {
    light: DirectionalLight
    sphere: Mesh

    constructor(scene: Scene) {
        this.light = new DirectionalLight("light", new Vector3(-1, -2, -1), scene);
        this.light.position = new Vector3(0, 20, 0);
        this.light.intensity = 3;

        const below = new DirectionalLight("below", new Vector3(1, -2, -1), scene);
        below.diffuse = Color3.FromHexString("#1BFFFF");
        below.intensity = 1.5;
        below.direction = new Vector3(0.107, 0.907, -0.406);
        below.shadowEnabled = false;

        if (!PRODUCTION) {
            this.sphere = MeshBuilder.CreateSphere("sphere", { diameter: 1 }, scene);
            this.sphere.position = new Vector3(0, 0, -2);
        }

        // const ground = MeshBuilder.CreateGround("ground", {width: 30, height: 30});
        // ground.position = new Vector3(0, -1, 0);
        // ground.receiveShadows = true;

        // const s = MeshBuilder.CreateSphere("sphere", { diameter: 1 }, scene);
        // s.setParent(this.sphere);
        // s.position = new Vector3(0,0, -2);
    }
}

export default Environment;